.ellipsised {
	overflow: hidden;
	text-overflow: ellipsis;
	text-wrap: wrap;
}

.overflow_tooltip_container {
	position: relative;
}

.tooltip {
	position: absolute;
	bottom: 100%;
	left: 0;
	z-index: 9999999;
	background-color: white;
	padding: 7px 15px;
	border-radius: 5px;
	color: var(--black-color);
	text-wrap: wrap;
}

.pointer {
	cursor: pointer;
}
