.container {
	display: flex;
	flex-direction: column;

	gap: 20px;
	color: var(--white-color);
}

.time_control{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	gap: 30px;
}

.time_select_item {
	flex: 4;
	justify-content: flex-end;
	gap: 8px;
	color: var(--white-color);
	font-family: Jost,sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.28px;
}

.white_display {
	color: var(--white-color);
}
.error {
	flex: 1;
	font-size: 11px;
	color: red;
}

.error_range{
	display: flex;
	gap: 10px;
	flex-wrap: wrap;

	.error_range_item{
		flex: 1 0 23%;
		max-width: 122px;
	}
}


@media screen and (max-width: 500px) {
	.time_control{
		flex-direction: column;
		align-items: stretch;
	}
}
