@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');
@import "react-alice-carousel/lib/alice-carousel.css";

:root {
	--active-link: #352c8d;
	--link-color: #303030;
	--button-wb: #e055ff;
	--white-color: #ffffff;
	--background-Base: #ffffff;
	--black-color: #000000;
	--active-link-ping: rgb(252, 245, 236);

	--text-shadow: 1px 1px 2px rgba(42, 82, 122, 0.9);
	--box-shadow: 0 2px 4px rgba(106, 120, 134, 0.2), 0 3px 4px rgba(191, 196, 201, 0.18),
	0 1px 5px rgba(191, 196, 201, 0.24);

	--overlay-normal: rgba(153, 153, 153, 0.30);
	--alice-color: #f0f8ff;
	--blue-color: #3f7eb6;
	--border-input: #b4bec8;
	--border-table: #888888;
	--border-card-order: #729f92;
	--box-shadow-btn: 5px 5px 9px rgba(5, 24, 43, 0.2), 5px 5px 9px rgba(17, 34, 51, 0.18),
	5px 5px 9px rgba(14, 33, 54, 0.24);
	--box-shadow-input: 0 2px 4px rgba(14, 57, 100, 0.2), 0 3px 4px rgba(23, 42, 61, 0.18),
	0 1px 5px rgba(18, 45, 71, 0.24);
	--brown-text: rgba(133, 12, 32, 0.9);
	--btn-hover-color: #f5ff00;
	--color-dayActive: rgb(255, 255, 255);
	--color-option: #363636;
	--color-dayDisabledColor: rgb(167, 167, 167);
	--color-field: #454545;
	--green: #1c8666;
	--text-G: #1C8666;
	--border-G: #1C8666;
	--background-Green-weak: #C0E3D8;
	--green-hover: #166B52;
	--green-dark: #1e8668;
	--green-light: rgb(33, 143, 110);
	--green-light-color: #b5f0b8;
	--Green-Solid-50: #E9F3F0;
	--green-text: #6ec3a9;
	--background-Alternative: #f4f4f4;
	--grey-color: #807c8d;
	--grey-light-color: rgb(225, 223, 233);
	--header-color: #ffffff;
	--button-Secondary: #ffffff;
	--button-Hover-B-Secondary: #EBEAF4;
	--primary-color: #00093c;
	--purple: #352c8d;
	--background-Brand-B: #352c8d;
	--text-B: #352C8D;
	--text-B-hover: #15124F;
	--border-B: #352c8d;
	--button-Hover-B-Primary: #15124F;
	--button-Desabled-Primary: #999;
	--purple-light: rgb(65, 54, 161);
	--red-color: #dd3333;
	--red-light-color: #f19e9e;
	--text-blue: #002064;
	--text-Strong: #303030;
	--title-card-service: #1B1B1B;
	--yellow: #cb8b2a;
	--border-Y: #cb8b2a;
	--yellow-light: rgb(214, 147, 46);
	--input-color: #303030;
	--input-error-border-color: #d700008a;
	--text-Weak: #4d4d4d;
	--border-Weak: #CFCFCF;
	--text-Accent: #1B1B1B;
	--black-brown: #202020;
	--gray-light: #e5e5e5;
	--gray-dark: #5a5a5a;
	--border-Neutral: #CFCFCF;
	--gray-middle: #aaaaaa;
	--disabled-bg: #858585;
	--text-Weaker: #7A7A7A;

	--red: #C51A1A;

	--font-family: 'Jost', sans-serif;


	--Spacing-desctop-H2: 40px;
	--Spacing-desctop-Block-L: 32px;
	--Spacing-desctop-Block-M: 24px;
	--Spacing-desctop-Block-S: 16px;
	--Spacing-desctop-Body-M: 8px;
	--Spacing-desctop-Body-S: 4px;

	--Corner-L: 16px;
	--Corner-12: 12px;
	--Corner-M: 8px;
	--Corner-S: 4px;
}

html {
	font-size: 10px;
	scroll-behavior: smooth;
}

body {
	position: relative;
	justify-content: center;
	width: 100%;
	height: 100%;
	font-family: 'Jost', sans-serif;
	color: var(--color-option);
	letter-spacing: 0.1px;
	overflow-x: hidden;
	background-color: #ffffff;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
	text-rendering: optimizeSpeed;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

ul,
ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd,
p {
	margin: 0;
}

a {
	color: inherit;
	text-decoration: none;
}

a:not([class]) {
	text-decoration-skip-ink: auto;
}

img {
	display: block;
	max-width: 100%;
}

h1 {
	font-weight: 600;
	font-size: 40px;
	line-height: 52px;
	font-family: Jost, sans-serif;
	text-align: left;
	color: var(--text-Strong);
	letter-spacing: 0;
}

h2 {
	font-weight: 600;
	font-size: 40px;
	line-height: 52px;
	font-family: Jost, sans-serif;
	text-align: left;
	color: var(--text-Strong);
	letter-spacing: 0;
}

input,
textarea,
select {
	padding: 0;
	font: inherit;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
}

input[type=number] {
	-moz-appearance: textfield;
}

textarea {
	resize: none;
}

button {
	border: none;
	background-color: transparent;
	cursor: pointer;
}

/****************************************/

.visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	white-space: nowrap;
	clip-path: inset(100%);
	clip: rect(0 0 0 0);
	overflow: hidden;
}

.main {
	position: relative;
	width: 100%;
	min-height: calc(100vh - 51px);
	padding: 90px 5% 20px;
	background: var(--white-color);
}

@media screen and (max-width: 767px) {
	.main {
		min-height: calc(100vh - 72px);
		padding: 9.5rem 2rem 5rem;
	}
}

.wrapper_box {
	width: 100%;
	padding: 15px 8%;
}

@media screen and (max-width: 1200px) {
	.wrapper_box {
		padding: 15px 6%;
	}
}

@media screen and (max-width: 800px) {
	.wrapper_box {
		padding: 15px 5%;
	}
}

@media screen and (max-width: 480px) {
	.wrapper_box {
		padding: 20px 3%;
	}
}

/*////////////BUTTONS//////////////*/

.btn_default {
	max-height: 50px;
	color: var(--black-color);
	background-color: var(--white-color);
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: all ease 0.7s;
	align-self: flex-start;
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	display: inline-flex;
	height: 34px;
	padding: 8px 16px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	flex-shrink: 0;
}

.btn_yellow {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	position: relative;
	max-height: 50px;
	padding: 14px 35px;
	font-weight: 500;
	font-size: 18px;
	line-height: 1.44em;
	font-family: Jost, sans-serif;
	color: var(--white-color);
	background-color: var(--yellow);
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: all ease 0.7s;
}

.btn_yellow:after {
	content: '';
	position: absolute;
	width: 268px;
	height: 268px;
	background: rgba(255, 255, 255, 0.10);
	border-radius: 50%;
	bottom: -60%;
	left: -65%;
}

.btn_yellow_highlight {
	background-color: var(--yellow);
}

@media (hover: hover) {
	.btn_yellow:hover {
		background-color: var(--yellow-light);
		box-shadow: var(--box-shadow-btn);
	}

	.btn_yellow:disabled {
		background: var(--border-input);
		background-color: var(--border-input);
		box-shadow: none;
	}

	.btn_yellow:disabled:hover {
		cursor: not-allowed;
	}
}

@media screen and (max-width: 450px) {
	.btn_yellow {
		max-height: 40px;
		padding: 12px 25px;
		font-size: 14px;
	}
}

.btn {
	min-width: 100px;
	height: 30px;
	padding: 3px 15px;
	font-weight: 600;
	font-size: 1.1rem;
	font-family: 'Roboto', sans-serif;
	color: var(--white-color);
	text-shadow: 1px 1px 1px rgba(43, 41, 41, 0.5);
	background: var(--blue-color);
	background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
	border: 1px solid rgba(0, 0, 0, 0.3);
	border-radius: 15px;
	box-shadow: var(--box-shadow-input);
	cursor: pointer;
	transition: all 0.3s ease;
}

.btn:hover {
	background-color: #ffb83a;
	background-image: linear-gradient(rgba(255, 207, 92, 0.7), rgba(223, 156, 38, 0.8));
	box-shadow: inset 0 1px 1px rgba(255, 255, 0, 0.6);
}

.btn:active {
	padding: 5px 15px;
	color: var(--primary-color);
	text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.6);
	background-color: var(--btn-hover-color);
	box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2);
}

.btn_light {
	color: var(--primary-color);
	background: transparent;
}

.btn_light:hover {
	color: var(--white-color);
	background: var(--blue-color);
	background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
}

.btn_light:active {
	color: var(--primary-color);
	background-color: #ffb83a;
	background-image: linear-gradient(rgba(255, 207, 92, 0.7), rgba(223, 156, 38, 0.8));
	box-shadow: inset 0 1px 1px rgba(255, 255, 0, 0.6);
}

.big_btn {
	width: 90%;
}

.btn_medium {
	width: 60%;
}

.btn:disabled {
	color: var(--grey-color);
	background: transparent;
	box-shadow: inset 0 1px 1px var(--white-color);
	cursor: not-allowed;
}

.center_btn {
	margin: 10px auto;
}

/*/////// TITLE ///////*/
.title_section {
	margin-bottom: 40px;
	font-weight: 600;
	font-size: 4rem;
	line-height: 5rem;
	text-align: left;
	color: var(--text-Strong);
}

.title_section_purple {
	color: var(--border-B);
}

.title_section_green {
	font-weight: 900;
	color: var(--green-text);
	text-shadow: 3px 3px 5px rgba(18, 41, 63, 1);
}

.title_section_dark_green {
	font-weight: 900;
	color: var(--green-dark);
}

.title_section_white {
	color: var(--white-color);
	text-shadow: 3px 3px 5px rgba(18, 41, 63, 1);
}

@media screen and (max-width: 990px) {
	.title_section {
		font-size: 3rem;
		line-height: 4rem;
	}
}

@media screen and (max-width: 575px) {
	.title_section {
		margin: 0 0 20px 0;
		font-size: 2.5rem;
	}
}

.text_short {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	max-height: 150px;
	overflow: hidden;
	line-height: 1.3em;
	text-overflow: ellipsis;

	-webkit-line-clamp: 3;
}

.text_short_org_card {
	max-height: 500px;

	-webkit-line-clamp: 5;
}

/*///////// MODAL/////////*/
.wrapper {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 20;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 100vh;
	background: rgba(54, 54, 54, 0.7);
}

.container_modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 30;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.container_modal_scroll {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 30;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.modal {
	z-index: 50;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: 300px;
	margin: 0 auto;
	padding: 20px;
	background: var(--white-color);
	border: 2px solid var(--blue-color);
	border-radius: 15px;
}

.modal_photo {
	position: relative;
	min-width: 50px;
	margin: auto 20px;
	padding: 40px 20px 35px;
	border: none;
}

.platform_card_logo_wrapper .alice-carousel__dots {
	display: flex;
	position: absolute;
	left: 0;
	width: 100%;
	bottom: 10px;
	padding: 0px 5px;
}

.platform_card_logo_wrapper .alice-carousel__dots-item:not(.__custom) {
	width: 100%;
	border-radius: 0;
	height: 3px;
}

.platform_card_logo_wrapper .alice-carousel__dots-item:not(.__custom):hover,
.platform_card_logo_wrapper .alice-carousel__dots-item:not(.__custom).__active {
	background-color: var(--yellow);
}

.multirows_slider.slick-initialized .slick-slide {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 6px;
}

ul.alice-carousel__dots {
	margin-top: -13px;
	position: absolute;
	width: 100%;
	display: flex;
	gap: 10px;
	padding: 0px 10px;
}

.alice-carousel__dots-item:not(.__custom) {
	border-radius: 0;
	height: 3px;
}

.alice-carousel__dots-item:not(.__custom):not(:last-child) {
	margin-right: 0;
}

ul.alice-carousel__dots > li {
	display: flex;
	flex: 1;
}

.alice-carousel__dots-item:not(.__custom).__active {
	background-color: var(--yellow);
}

.nowrap {
	text-wrap: nowrap;
}

.required_fields {
	color: var(--red-color);
}
