.section_order {
	justify-content: center;
	max-width: unset;
	padding: 147px 15px;
	background-image: url('./../../../assets/img/start-section4.png');
	background-repeat: no-repeat;
	background-position: right;
	background-size: cover;

	display: grid;
	grid-gap: 30px;
	grid-template-areas:
		 "title card"
		 "form card";

	gap: 125px;
}

.container_title {
	display: flex;
	flex-direction: column;

	gap: 20px;
}

.title {
	margin-bottom: 0;
	font-size: 40px;
	line-height: 1.3em;
}

.container_card {
	display: grid;
	flex-grow: 1;
	max-width: 694px;
	padding: 26px 0;

	grid-gap: 15px;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
}

.card {
	max-width: 335px;
	max-height: 312px;
	padding: 35px;
	border-radius: 8px;
}

.card:nth-child(1) {
	background-color: var(--white-color);
}

.card:nth-child(2) {
	background-color: var(--purple-light);
}

.card:nth-child(3) {
	background-color: var(--yellow-light);
}

.card:nth-child(4) {
	background-color: var(--green-light);
}

.card_title {
	font-weight: 600;
	font-size: 40px;
	line-height: 1.3em;
}

.black_text {
	color: var(--text-Strong);
}

.white_text {
	color: var(--white-color);
}

.card_text {
	margin-top: 20px;
	font-weight: 400;
	font-size: 2.6rem;
	line-height: 3.3rem;
}

.container_title {
	grid-area: title;
}

.container_form {
	grid-area: form;
}

.container_card {
	grid-area: card;
}

@media screen and (max-width: 1400px) {
	.card_title {
		font-size: 2.8rem;
		line-height: 3.5rem;
	}

	.card_text {
		margin-top: 40px;
		font-size: 2rem;
		line-height: 2.8rem;
	}
}

@media screen and (max-width: 1200px) {
	.card {
		padding: 30px 25px 20px;
	}

	.card_title {
		font-size: 2.5rem;
		line-height: 3.2rem;
	}

	.card_text {
		margin-top: 10px;
		font-size: 1.8rem;
		line-height: 2.1rem;
	}

	.container_card {
		margin-top: 135px;
	}
}

@media screen and (max-width: 990px) {
	.section_order {
		padding: 50px 15px;
		align-items: center;
		display: grid;
		grid-gap: 30px;
		grid-template-areas:
		 "title"
		 "card"
		 "form"
	}

	.container_form {
		display: flex;
		justify-content: center;
	}

	.card {
		padding: 30px 25px 20px;
	}

	.card_title {
		font-size: 2.2rem;
		line-height: 2.5rem;
	}

	.card_text {
		font-size: 1.6rem;
		line-height: 2rem;
	}

	.container_card {
		margin: 0;
		padding: 0;
	}
}

@media screen and (max-width: 800px) {
	.title {
		font-size: 24px;
		line-height: 1.3em;
	}
}

@media screen and (max-width: 768px) {
	.container_card {
		display: flex;
		flex-direction: column;
	}
}


@media screen and (max-width: 450px) {
	.section_order {
		padding: 50px 15px;
	}

	.card {
		padding: 20px;
	}

	.card_title {
		font-size: 1.6rem;
		line-height: 2rem;
	}

	.card_text {
		font-size: 1.4rem;
		line-height: 1.8rem;
	}
}
