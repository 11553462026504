.wrapper {
	position: absolute;
	z-index: 4;
	top: -14%;
	left: -40%;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.calendar {
	width: 260px;
	border-radius: 8px;
	background: #fff;
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
	color: black;
}

@media (max-width: 992px) {
	.wrapper {
		left: -19%;
	}
}

@media (max-width: 575px) {
	.wrapper {
		left: -2%;
	}
}
