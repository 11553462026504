.container {
	position: relative;
	width: 100%;
}

.label {
	color: #000000;
	font-weight: 600;
	font-size: 1.4rem;
}

.items__container {
	z-index: 2;
	width: 293px;
	position: absolute;
	background: #FFFFFF;
	margin: 0;
	font-size: 1.4rem;
	border-radius: 5px;
	border: 1.2px solid #aaa;
	padding: 10px 15px;
}

.items__general {
	width: 100%;
}

.items {
	display: flex;
	flex-direction: column;
	height: 130px;
	overflow: auto;
}

.items::-webkit-scrollbar, .btns::-webkit-scrollbar {
	width: 6px;
}

.items::-webkit-scrollbar-track, .btns::-webkit-scrollbar-track {
	background: #EAEAEA;
	border-radius: 20px;
}

.items::-webkit-scrollbar-thumb, .btns::-webkit-scrollbar-thumb {
	background-color: #AAAAAA;
	border-radius: 20px;

}


.error-wrapper {
	display: none;
	position: absolute;
	z-index: 2;
	right: 0;
	top: -20px;
	border-radius: 5px;
	background: var(--yellow);
	padding: 11px 13px;
	color: #FFF;
	width: 150px;
	font-family: var(--font-family);
	font-size: 14px;
}

.hidden-error {
	display: flex;
}

.hidden {
	display: none;
}

.error {
	display: block;
}

.item {
	display: flex;
	flex-direction: column;
	cursor: pointer;
	padding: 4px;
    &:hover{
		background-color: rgba(0, 0, 0, 0.05);
	}
	& > span {
		word-wrap: break-word;
	}
}

.btns {
	margin: 2rem 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 2rem;
	max-height: 119px;
	overflow: auto;
}

.btn {
	display: flex;
	align-items: center;
	border-radius: 5px;
	background: #1C8666;
	padding: 13px 14px;
	/*height: 37px;*/
}

.btn-title {
	color: #fff;
	font-size: 16px;
	font-family: var(--font-family);
}

.form__item-pad {
	padding: 10px 15px;
}

.btn-icon {
	margin-left: 2.7rem;
	cursor: pointer;
}

@media (max-width: 1200px) {
	.btns {
		height: inherit;

	}
}

@media (max-width: 575px) {
	.btns {
		align-items: center;
	}
}
