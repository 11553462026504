.counter {
	display: flex;
	align-items: center;
	font-size: 16px;
	line-height: 1.44em;
	letter-spacing: 0.02em;

	gap: 9px;
}

.counter_count {
	min-width: 24px;
	text-align: center;
	user-select: none;
}

.counter_control {
	opacity: 0.5;
}

.counter_control_active {
	opacity: 1;
}

.counter_control_active:hover {
	cursor: pointer;
}

.counter_control_increase {
	transform: rotate(180deg);
}
