.option {
	padding: 4px 0 4px 8px;
	font-size: 14px;
	color: var(--color-field);
	text-wrap: wrap;
}

.option:hover {
	background-color: rgba(0, 0, 0, 0.05);
	cursor: pointer;
}

.option_active {
	font-weight: 700;
}
