.pagination_container,
.pagination {
	display: flex;
	align-items: center;
	font-weight: 600;
	font-size: 13px;
	line-height: 1.46em;
	justify-content: center;
	gap: 5px;
}

.base_link {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	cursor: pointer;
}

.break_link {
	align-items: flex-end;
}

.page_link {
	padding-top: 2px;
	border: 1px solid rgba(170, 170, 170, 0.47);
	border-radius: 50%;
}

.button_disabled {
	display: none;
}

.page_active {
	background: none;
}

.page_link_active {
	color: var(--white-color);
	background-color: rgba(53, 44, 141, 1);
	border-color: rgba(53, 44, 141, 1);
	cursor: default;
}

.first_page_label,
.previous_label {
	transform: rotate(180deg);
}
