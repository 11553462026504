.description_text_wrapper {
	width: 100%;
}

.description_text {
	font-size: 14px;
	white-space: pre-wrap;
}

.show_button {
	padding: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.button {
	padding: 10px 15px;
	font-size: 14px;
	line-height: 1.44em;
	background: var(--border-B);
}
@media(hover:hover) {
	.button:hover {
		background: var(--border-B);
		box-shadow: var(--box-shadow-btn);
	}
}
.show_block_description {
	height: max-content;
}

.unShow_block_description {
	height: 31px;
	overflow: hidden;
}
