.city_info {
	display: none;
}

@media screen and (min-width: 600px) {
	.city_info {
		display: block;
	}

	.city_info {
		position: relative;
	}

	.city_info:hover {
		cursor: pointer;
	}

	.city_info_content {
		display: flex;
		align-items: center;

		gap: 7px;
	}

	.city_info_title {
		font-size: 14px;
		line-height: 1.445em;
		color: #404040;
		text-transform: uppercase;
		white-space: nowrap;
	}

	.city_info_details {
		position: absolute;
		top: calc(100% + 15px);
		right: 60%;
		z-index: 5;
		display: flex;
		flex-direction: column;
		width: fit-content;
		padding: 30px 50px 35px;
		color: var(--black-brown);
		background-color: var(--white-color);
		border: 1.2px solid #aaaaaa;
		border-radius: 5px;

		gap: 10px;
	}

	.city_info_details:hover {
		cursor: default;
	}

	.city_info_details_label {
		font-size: 14px;
		line-height: 1.445em;
		color: #aaaaaa;
		text-transform: uppercase;
		white-space: nowrap;
	}

	.city_info_details_select {
		padding: 8px 10px 8px 16px;
	}

	.city_info_details_select_options_container {
		position: relative;
		top: -6px;
	}

	.city_info_details_select_option {
		padding-left: 15px;
	}

	.submit_button {
		margin-top: 10px;
		padding: 10px 35px;
		font-size: 14px;
		line-height: 1.445em;
	}
}
