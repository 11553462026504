.btn_container {
	width: 100%;
	padding: 12px 25px;
	border-radius: 5px;
	overflow: hidden;
	justify-content: center;
	align-items: center;
	gap: 10px;
	display: inline-flex;
	position: relative;
	cursor: pointer;
	height: 46px;
	font-family: Jost, sans-serif;
	color: var(--white-color);
	font-weight: 500;
	font-size: 18px;
	transition: 0.7s
}

.btn_decoration {
	width: 120%;
	height: 270px;
	background: rgba(255, 255, 255, 0.10);
	border-radius: 50%;
	position: absolute;
	top: -210px;
	left: -100px;
}

.label_container {
	word-wrap: break-word;
	display: flex;
	gap: 16px;
	justify-content: center;
	align-items: center;
	text-wrap: nowrap;
}

.white {
	background-color: var(--white-color);

	.label_container {
		color: #303030;
	}
}

.yellow {
	background-color: var(--yellow);
}

.purple {
	background-color: var(--border-B);
}

.green {
	background-color: var(--green);
}

.search_button:disabled {
	background: var(--border-input);
	box-shadow: none;
}


.disabled {
	cursor: not-allowed;

	.btn_decoration {
		display: none;
	}

}

.disabled.light {
	background-color: rgba(239, 239, 239, 0.3);

	.label_container {
		color: rgba(16, 16, 16, 0.302);
	}
}

.disabled.dark {
	background-color: #b4bec8;

	.label_container {
		color: white;
	}
}

@media (hover: hover) {
	.disabled.light:hover {
		background-color: rgba(239, 239, 239, 0.3);
	}

	.disabled.dark:hover {
		background-color: #b4bec8;
	}

	.btn_container:hover {
		box-shadow: var(--box-shadow-btn);
	}

	.disabled:hover {
		box-shadow: none;
	}

	.white:hover {
		background-color: var(--white-color);
		box-shadow: none;
	}

	.yellow:hover {
		background-color: var(--yellow-light);
	}

	.purple:hover {
		background-color: var(--purple-light);
	}

	.green:hover {
		background-color: var(--green-light);
	}
}

@media screen and (max-width: 768px) {
	.btn_container {
		max-width: 100%;
	}
}
