.burger_menu_button {
	min-width: 50px;
	min-height: 44px;
	background-color: var(--border-B);
	border: none;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.burger_menu_button:hover {
	cursor: pointer;
}
