.calender_container {
	position: absolute;
	top: calc(100% - 6px);
	left: 0;
	z-index: 5;
	display: flex;
	justify-content: center;
	width: 100%;
	padding-bottom: 20px;
	background-color: var(--white-color);
	border: 1.2px solid var(--border-input);
	border-top: none;
	border-radius: 5px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
