.field_base {
	position: relative;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	width: 100%;
	padding: 10px 15px;
	border: 1px solid var(--border-input);
	border-radius: 5px;
	font-size: 14px;
	gap: 5px;
}

.field_base:hover {
	cursor: pointer;
}

.content_container {
	display: flex;
	align-items: center;
	width: 100%;

	color: var(--color-field);
}

.content_container_before_icon {
	display: flex;
	justify-content: space-between;
	width: 35px;
	margin-right: 15px;
}

.content_container_after_icon {
	display: flex;
	margin-right: -5px;
	margin-left: 5px;
}
