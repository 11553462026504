.order_details {
	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: space-between;
	font-size: 12px;
}

.details {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 12px 0;
}

.details_box {
	display: flex;
	flex-direction: column;
	gap: 10px;

}

.attached_orders_label {
	font-weight: 600;
	font-size: 16px;
	line-height: 21px;
	color: #303030;
}

.attached_orders {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
	gap: 28px;
}

.attached_orders_container {
	background: var(--background-Alternative);
	border-radius: 8px;
	width: min(100%, 474px);
	position: relative;
}

.attached_orders_img {
	border-radius: 8px;
	border: 1px solid var(--gray-middle);
}

.attached_orders_content {
	display: flex;
	gap: 18px;
}

.attached_orders_text_wrapper {
	padding-top: 10px;
	display: flex;
	flex-direction: column;
	gap: 6px;
	justify-content: flex-start;
}

.attached_orders_title {
	font-weight: 500;
	font-size: 18px;
	line-height: 23px;
	color: #000;
}

.attached_orders_count {
	font-weight: 400;
	font-size: 16px;
	letter-spacing: 0.02em;
	color: var(--link-color);
}

.button_remove {
	display: flex;
	gap: 10px;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	background: var(--border-B);
	font-weight: 400;
	font-size: 14px;
	text-align: center;
	color: #fff;
	position: absolute;
	right: 10px;
	bottom: 10px;
	padding: 2px 8px;
	cursor: pointer;
	transition: 0.5s;

	&:hover {
		box-shadow: var(--box-shadow-btn);
	}
}

.attached_orders_org_title {
	padding: 2px 8px;
	border-radius: 6px;
	background: var(--yellow);
	font-weight: 400;
	font-size: 14px;
	text-align: center;
	color: #fff;
	position: absolute;
	right: 10px;
	top: 10px;
}

.remove_icon {
	filter: hue-rotate(1220deg) brightness(12200%);
	width: 10px;
	height: 10px;
}

@media screen and (max-width: 768px) {
	.details_box {
		width: 50%;
	}

	.attached_orders_container {
		width: 100%;
	}

	.attached_orders_text_wrapper {
		gap: 30px;
	}

	.attached_orders_org_title {
		left: 120px;
		top: 36px;
		width: fit-content;
	}
}

@media screen and (max-width: 390px) {
	.details_box {
		width: 100%;
	}
}
