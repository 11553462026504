.tag {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px 10px;
	color: var(--white-color);
	background-color: var(--border-B);
	border-radius: 5px;
	width: fit-content;
}
