.section_map {
	display: flex;
	padding-bottom: 40px;
	background-image: url("../../../assets/img/start_section3.png");
	background-repeat: no-repeat;
	background-position: bottom right;
	background-size: 50%;
}

.title {
	margin-bottom: 67px;
}

.container_text {
	width: 40%;
}

.container_flex {
	display: flex;
}

.text {
	margin-bottom: 128px;
	font-weight: 400;
	font-size: 2.4rem;
	line-height: 3.1rem;
}

.view_on_map_btn{
	width: 250px;
	height: 46px;
	border-radius: 4px !important;
}

@media screen and (max-width: 1200px) {
	.section_map {
		background-position: bottom right;
		padding-bottom: 0;
	}

	.title {
		margin-bottom: 20px;
	}

	.text {
		font-size: 2.2rem;
		line-height: 2.8rem;
	}
}

@media screen and (max-width: 1030px) {
	.section_map {
		background-size: 45%;
	}


	.title {
		font-size: 24px;
		line-height: 31px;
	}

	.text {
		margin: 20px 0 45px 0;
		font-size: 2rem;
		line-height: 2.6rem;
	}
}

@media screen and (max-width: 850px) {
	.section_map {
		background-size: 50%;
	}

	.container_text {
		min-width: 45%;
	}

	.text {
		font-size: 1.8rem;
		line-height: 2.4rem;
	}
}

@media screen and (max-width: 790px) {
	.container_img {
		display: none;
	}

	.img_mobile {
		display: block;
		width: 35%;
		height: 100%;
	}

	.text {
		margin: 10px 0 45px 0;
	}
}

@media screen and (max-width: 600px) {
	.container_text {
		width: 70%;
	}

	.text {
		font-size: 1.6rem;
		line-height: 2.2rem;
		margin-bottom: 0;
		min-height: 155px;
	}

	.section_map {
		padding-bottom: 0;
		background-image: url("../../../assets/img/start_section3_1.png");
		background-position: top 10px right -70px;
		background-size: 201px 237px;
	}
}

@media screen and (max-width: 500px) {
	.container_flex {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 40px;
	}
}
