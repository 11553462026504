.openable_field_base {
	position: relative;
	width: 100%;
}

.toggle_icon {
	margin-right: 3px;
	margin-left: 6px;
	transform: rotate(0deg);
	transition: all 0.2s;
}

.toggle_icon_opened {
	transform: rotate(90deg);
}

.display_value {
	display: inline-block;
	flex-grow: 1;
	font-size: 14px;
	line-height: 1.71em;
	vertical-align: middle;
	color: var(--grey-color);
	text-wrap: nowrap;
}

.display_value_filled {
	color: black;
}

@media screen and (max-width: 360px) {
	.custom_select_field {
		padding: 12px 10px;
	}

	.display_value {
		font-size: 12px;
	}
}
