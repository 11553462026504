.container_big {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 9999999999;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.message__container {
	z-index: 90;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 450px;
	padding: 30px 60px;
	color: var(--primary-color);
	background: #ffffff;
	border-radius: 16px;
	box-shadow: var(--box-shadow);

	gap: 20px;
}

.message__title {
	font-weight: 700;
	font-size: 1.8rem;
	line-height: 2.4rem;
	text-align: center;
}

.message__title_err {
	color: var(--red-color);
}

.message__text {
	font-weight: 600;
	font-size: 1.6rem;
	text-align: center;
}

.message__btn {
	height: 40px;
	padding: 5px 20px;
	font-weight: 600;
	font-size: 1.2rem;
	color: var(--white-color);
	text-transform: uppercase;
	text-shadow: 1px 1px 1px rgba(43, 41, 41, 0.5);
	/*background:linear-gradient(231.58deg, #F83600 -53.35%, #F9D423 297.76%);*/
	background: var(--blue-color);
	background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
	border: 1px solid rgba(0, 0, 0, 0.3);
	border-radius: 15px;
	box-shadow: inset 0 1px rgba(255, 255, 255, 0.3);
	cursor: pointer;
	transition: all ease-in 0.4s;
}

.message__btn:hover {
	background-color: #ffb83a;
	background-image: linear-gradient(rgba(255, 207, 92, 0.7), rgba(223, 156, 38, 0.8));
	box-shadow: inset 0 1px 1px rgba(255, 255, 0, 0.6);
}

.message__btn:active {
	padding: 13px 20px 11px;
	color: var(--primary-color);
	text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.6);
	background-color: var(--btn-hover-color);
	box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2);
}

.close_btn {
	z-index: 90;
	align-self: flex-end;
	width: 30px;
	height: 30px;
	padding: 2px;
	font-weight: 600;
	font-size: 1.4rem;
	color: var(--grey-color);
	border: 1px solid var(--grey-color);
	border-radius: 50%;
	cursor: pointer;
	transition: all ease 0.4s;
}

.close_btn:hover {
	color: var(--blue-color);
	border: 2px solid var(--blue-color);
}

@media screen and (max-width: 650px) {
	.message__container {
		top: 90px;
		left: 10%;
		width: 80%;
		padding: 60px 60px;
	}
}

@media screen and (max-width: 570px) {
	.message__container {
		width: 85%;
		padding: 60px 40px;
	}

	.message__title {
		font-size: 1.8rem;
		text-align: center;
	}

	.message__text {
		font-weight: 400;
		font-size: 1.5rem;
		line-height: 20px;
		text-align: center;
	}

	.message__btn {
		width: 256px;
		height: 40px;
		font-size: 1.2rem;
	}
}
