.base_tooltip {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	color: var(--white-color);
	background-color: var(--yellow);
	border-radius: 5px;
	z-index: 1;
}
