.time_select {
	display: flex;
	align-items: center;

	gap: 15px;
}

.title {
	min-width: 52px;
}

.time_single_select {
	display: flex;
	width: unset;
	padding: 8px;
}
