.contacts_list_item_content {
	display: flex;
	justify-content: space-between;
	overflow: hidden;
	gap: 5px;
}

.contacts_list_item_label {
	overflow: hidden;
	line-height: 1.36em;
	text-overflow: ellipsis;
	word-break: break-word;
}
.contacts_list{
	.contacts_list_item_label {
		font-size: 16px;
	}
}

.contacts_list_item_select {
	width: max-content;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	& span:first-child{
		font-size: 18px;
		line-height: 24px;
	}
	& span:last-child{
		font-size: 14px;
		line-height: 20px;
	}
}

.selector_container {
	width: 115px;
	padding: 4px 6px;
}


.selector_container_date {
	width: 70px;
	padding: 4px 6px;
}

.link:hover{
	color: var(--green);
}

.contacts_list_item_label{
	font-size: 14px;
}
