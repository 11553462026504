.section_title {
	margin-bottom: 60px;
	padding: 9rem 8rem;
	background-image: url('./../../../assets/img/start_page1.png');
	background-repeat: no-repeat;
	background-position: right;
	background-size: cover;
	border-radius: 8px;
}

.title {
	margin-bottom: 216px;
	font-weight: 600;
	font-size: 5rem;
	line-height: 6.5rem;
	color: var(--white-color);
	text-shadow: 3px 3px 5px rgba(18, 41, 63, 1);
}

/* sports/platforms start page block */
.ag_format_container {
	position: absolute;
	right: 52px;
	bottom: -60px;
}

.ag_courses_box {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	width: 100%;

	gap: 18px;
}

.ag_courses_item {
	width: 213px;
	height: 275px;
	border-radius: 8px;
}

.ag_courses_item_link {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 210px;
	height: 275px;
	overflow: hidden;
	border-radius: 8px;
}

.ag_courses_item:nth-child(1) {
	background: #1c8666;
}

.ag_courses_item:nth-child(2) {
	background: var(--new-purple, #352c8d);
}

.ag_courses_item:nth-child(3) {
	background: var(--main, #cb8b2a);
}

.img_card {
	z-index: 2;
	max-height: 95px;
	margin: 25px auto;
}

.ag_courses_item_link:hover,
.ag_courses_item_link:hover .ag_courses_item_date {
	color: #ffffff;
	text-decoration: none;
}

.ag_courses_item_link:hover .ag_courses_item_bg {
	-webkit-transform: scale(10);
	-ms-transform: scale(10);
	transform: scale(10);
}

.ag_courses_item_title {
	position: relative;
	z-index: 2;
	max-width: 180px;
	margin-bottom: 16px;
	overflow: hidden;
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 3.5rem;
	text-align: center;
	color: var(--white-color);
}

.ag_courses_item_bg {
	position: absolute;
	top: -115px;
	right: -15px;
	z-index: 1;
	width: 240px;
	height: 300px;
	background: rgba(0, 0, 0, 0.05);
	border-radius: 10000px;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.ag_courses_item:nth-child(2n) .ag_courses_item_bg {
	background: var(--white-color);
	opacity: 0.05;
}

.ag_courses_item:nth-child(3n) .ag_courses_item_bg {
	background: var(--white-color);
	opacity: 0.05;
}

@media screen and (max-width: 1000px) {
	.section_title {
		padding: 9rem 7rem;
		border-radius: 0;
	}

	.title {
		margin-bottom: 150px;
		font-size: 4rem;
		line-height: 5.5rem;
	}

	.ag_format_container {
		bottom: -45px;
	}

	.ag_courses_item_bg {
		top: -100px;
	}

	.ag_courses_item,
	.ag_courses_item_link {
		width: 152px;
		height: 200px;
	}

	.img_card {
		max-height: 75px;
	}

	.ag_courses_item_bg {
		width: 180px;
		height: 225px;
	}

	.ag_courses_item_title {
		max-width: 120px;
		font-size: 1.8rem;
		line-height: 2.5rem;
	}
}

@media screen and (max-width: 768px) {
	.section_title {
		padding: 48px 15px 37px;
	}

	.title {
		margin-bottom: 20px;
		font-size: 3rem;
		line-height: 4.5rem;
	}

	.ag_format_container {
		bottom: -120px;
		left: 0;
		right: 0;
	}

	.ag_courses_box {
		justify-content: center;
	}

	.ag_courses_item_bg {
		top: -90px;
	}

	.ag_courses_item,
	.ag_courses_item_link {
		width: 114px;
		height: 150px;
	}

	.img_card {
		max-height: 45px;
	}

	.ag_courses_item_bg {
		width: 144px;
		height: 180px;
	}

	.ag_courses_item_title {
		max-width: 100px;
		font-size: 1.4rem;
		line-height: 2rem;
	}
}

@media screen and (max-width: 550px) {
	.section_title {
		padding: 7rem 4rem;
	}

	.title {
		font-size: 2.8rem;
		line-height: 3.5rem;
	}

	.ag_format_container {
		right: 0;
	}
}

@media screen and (max-width: 460px) {
	.title {
		margin-bottom: 0;
	}

	.title span {
		font-size: 2rem;
		line-height: 3.5rem;
		text-align: center;
	}

	.ag_format_container {
		bottom: -80px;
	}

	.ag_courses_item_bg {
		top: -60px;
	}

	.ag_courses_item,
	.ag_courses_item_link {
		width: 80px;
		height: 105px;
	}

	.img_card {
		max-height: 30px;
		margin: 16px auto 15px;
	}

	.ag_courses_item_bg {
		width: 109px;
		height: 120px;
	}

	.ag_courses_item_title {
		max-width: 70px;
		font-size: 1.1rem;
		line-height: 1.4rem;
	}
}

@media screen and (max-width: 350px) {
	.title {
		font-size: 2.4rem;
		line-height: 3.2rem;
	}
}
