.selector_calendar{
	position: relative;
}
.contacts_list_item_select {
	width: max-content;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	& span:first-child{
		font-size: 18px;
		line-height: 24px;
	}
	& span:last-child{
		font-size: 14px;
		line-height: 20px;
	}
}
