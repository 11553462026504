.card_box {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 150px;
	padding: 0.8rem 1.2rem;
	background: var(--alice-color);
	border-radius: 0.5rem;
	box-shadow: var(--box-shadow-input);

	gap: 1.2rem;
}

.card_title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	font-weight: 600;
	font-size: 1.4rem;
	text-align: center;
	color: #002064;

	gap: 10px;
}

.card_box:hover {
	box-shadow: var(--box-shadow-btn);
}

.btn_delete {
	background-color: transparent;
	border: none;
	cursor: pointer;
	opacity: 0.5;
	transition: all ease 0.7s;
}

.btn_delete:hover {
	opacity: 1;
}

.title {
	font-weight: 600;
	font-size: 1.2rem;
	text-align: center;
	color: #002064;
}

@media screen and (max-width: 767px) {
	.card {
		min-width: 10rem;

		gap: 0.8rem;
	}
}

@media screen and (max-width: 480px) {
	.card {
		padding: 0.4rem 0.8rem;
	}
}
