.map {
	width: 100%;
	max-width: 100rem;
	margin: 0 auto;
	border: 1px solid var(--grey-color);
}

.map_loader {
	display: flex;
	justify-content: center;
	width: 100%;
	margin: 3rem auto;
	padding: 10rem 5rem;
}
