.list_with_icons_item {
	display: flex;
	align-items: center;
}

.list_with_icons_item_icon {
	display: flex;
	align-items: center;
	margin-right: 6px;
}

.list_with_icons_item_content {
	display: flex;
	flex-grow: 1;
	align-items: center;
	flex-wrap: wrap;
	flex: 1;
}

.list_with_icons_item_interactive:hover {
	cursor: pointer;
}
