.rdp {
	--rdp-accent-color: #352c8d;
	--rdp-accent-color-dark: #3003e1;
	--rdp-background-color: #e7edff;
	--rdp-background-color-dark: #180270;
	--rdp-caption-font-size: 18px;
	--rdp-cell-size: 30px;
	--rdp-outline: 2px solid var(--rdp-accent-color);
	--rdp-outline-selected: 3px solid #352c8d;
}

.rdp-vhidden {
	position: absolute !important;
	top: 0;
	box-sizing: border-box;
	width: 1px !important;
	height: 1px !important;
	margin: 0;
	padding: 0;
	padding: 0 !important;
	overflow: hidden !important;
	background: transparent;
	border: 0;
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;

	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.rdp-button_reset {
	position: relative;
	margin: 0;
	padding: 0;
	font: inherit;
	color: inherit;
	background: none;
	cursor: default;

	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.rdp-button_reset:focus-visible {
	outline: none;
}

.rdp-button {
	border: 2px solid transparent;
}

.rdp-button[disabled]:not(.rdp-day_selected) {
	opacity: 0.25;
}

.rdp-button:not([disabled]) {
	cursor: pointer;
}

.rdp-button:focus-visible:not([disabled]) {
	color: inherit;
	background-color: var(--rdp-background-color);
	border: var(--rdp-outline);
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
	background-color: var(--rdp-background-color);
}

.rdp-months {
	display: flex;
	width: 100%;
	min-width: 275px;
	max-width: 320px;
	padding: 12px;
	background-color: var(--white-color);
	border-radius: 10px;
}

.rdp-month {
	margin: 0 1em;
}

.rdp-month:first-child {
	margin-left: 0;
}

.rdp-month:last-child {
	margin-right: 0;
}

.rdp-caption_start {
	width: 100%;
}

.rdp-table {
	width: 100%;
	margin: 0;
}

.rdp-with_weeknumber .rdp-table {
	max-width: calc(var(--rdp-cell-size) * 7);
	border-collapse: collapse;
}

.rdp-caption {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0;
	text-align: left;
}

.rdp-multiple_months .rdp-caption {
	position: relative;
	display: block;
	text-align: center;
}

.rdp-caption_dropdowns {
	position: relative;
	display: inline-flex;
}

.rdp-caption_label {
	position: relative;
	z-index: 1;
	display: inline-flex;
	align-items: center;
	margin: 0;
	padding: 0 0.25em;
	font-weight: bold;
	font-size: 16px;
	font-family: 'Jost';
	color: #555555;
	white-space: nowrap;
	border: 0;
	border: 2px solid transparent;
}

.rdp-nav {
	white-space: nowrap;
}

.rdp-multiple_months .rdp-caption_start .rdp-nav {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}

.rdp-multiple_months .rdp-caption_end .rdp-nav {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}

.rdp-nav_button {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;
	padding: 0.25em;
	border-radius: 100%;
}

.rdp-nav_button svg {
	width: 100% !important;
	margin-right: 0 !important;
}

.rdp-dropdown_year,
.rdp-dropdown_month {
	position: relative;
	display: inline-flex;
	align-items: center;
}

.rdp-dropdown {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	margin: 0;
	padding: 0;
	padding-left: 10px;
	font-size: inherit;
	line-height: inherit;
	font-family: 'Jost';
	color: gray;
	text-transform: capitalize;
	background-color: transparent;
	border: none;
	cursor: inherit;
	opacity: 0;

	appearance: none;
}

.rdp-dropdown[disabled] {
	color: unset;
	opacity: unset;
}

.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
	background-color: var(--rdp-background-color);
	border: var(--rdp-outline);
	border-radius: 6px;
}

.rdp-dropdown_icon {
	margin: 0 0 0 5px;
}

.rdp-head {
	border: 0;
}

.rdp-head_row,
.rdp-row {
	height: 100%;
}

.rdp-head_cell {
	height: var(--rdp-cell-size);
	padding: 0;
	font-weight: 500;
	font-size: 14px;
	font-family: 'Jost';
	vertical-align: middle;
	text-align: center;
	color: gray !important;
	text-transform: uppercase;
}

.rdp-tbody {
	border: 0;
}

.rdp-tfoot {
	margin: 0.5em;
}

.rdp-cell {
	width: var(--rdp-cell-size);
	height: var(--rdp-cell-size);
	padding: 0;
	text-align: center;
}

.rdp-weeknumber {
	font-size: 0.75em;
}

.rdp-weeknumber,
.rdp-day {
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	width: var(--rdp-cell-size);
	max-width: var(--rdp-cell-size);
	height: var(--rdp-cell-size);
	margin: 0;
	overflow: hidden;
	font-size: 16px;
	border: 2px solid transparent;
	border-radius: 100%;
}

.rdp-day_today:not(.rdp-day_outside) {
	font-weight: bold;
	color: #555555;
}

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
	color: white !important;
	background: #352c8d !important;
	border: 2px solid #352c8d !important;
	opacity: 1 !important;
}

.rdp-day_outside {
	opacity: 0.4;
}

.rdp-day_selected:focus-visible {
	z-index: 1;
	outline: #352c8d;
	outline-offset: 2px;
}

.rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.rdp[dir='rtl'] .rdp-day_range_start:not(.rdp-day_range_end) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.rdp[dir='rtl'] .rdp-day_range_end:not(.rdp-day_range_start) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.rdp-day_range_end.rdp-day_range_start {
	border-radius: 100%;
}

.rdp-day_range_middle {
	border-radius: 0;
}

.footer_tab {
	margin: 25px auto 10px;
	font-weight: 600;
	font-size: 1.3rem;
	color: var(--primary-color);
}

@media screen and (max-width: 400px) {
	.rdp-months {
		margin: 0 auto;
		width: 275px;
	}
}
