.time_schedule_slot_cell {
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 15px 8px 6px ;
	background-color: var(--white-color);
	border-radius: 2px;
	position: relative;
	min-width: 110px;
	.base_tooltip{
		background-color: transparent;
		padding: 0;
		z-index: 100;
	}
	.time_schedule_slot_cell_time{
		text-wrap: nowrap;
	}

	&.active,.active  {
		background-color: var(--green);
		.time_schedule_slot_cell_time {
			color: var(--white-color)
		}
		.time_schedule_slot_cell_count {
			color: rgba(255, 255, 255, 0.67);
		}
	}
	&.busy,.busy {
		background-color: var(--yellow);
		.time_schedule_slot_cell_time {
			color: var(--white-color)
		}
		.time_schedule_slot_cell_count {
			color: rgba(255, 255, 255, 0.67);
		}
	}
	&.ordered,.ordered {
		background-color: var(--green);
		.time_schedule_slot_cell_time {
			color: var(--white-color)
		}
		.time_schedule_slot_cell_count {
			color: rgba(255, 255, 255, 0.67);
		}
	}
	&.errored,.errored {
		background-color: var(--red);
		.time_schedule_slot_cell_time {
			color: var(--white-color)
		}
		.time_schedule_slot_cell_count {
			color: rgba(255, 255, 255, 0.67);
		}
	}
	.cell_tooltip{
		color: #FFF;
		font-family: Jost, sans-serif;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		width: 150px;
		z-index: 100;
	}




}
.tooltip_wrapper_box{
	display: flex;
	flex-direction: column;
	flex: 1;
}





.time_schedule_slot_cell.disabled{
	background-color: var(--disabled-bg);
	.time_schedule_slot_cell_time {
		color: var(--white-color)
	}
	.time_schedule_slot_cell_count {
		color: rgba(255, 255, 255, 0.67);
	}
}

.time_schedule_slot_cell:hover {
	cursor: pointer;
}

.time_schedule_slot_cell_time {
	font-size: 13px;
	line-height: 1.44em;
	color: var(--text-Strong);
	margin-right: 5px;
}

.time_schedule_slot_cell_count {
	font-size: 12px;
	line-height: inherit;
	line-height: 1.44em;
	color: #729f92;
}
.service_info_icon{
	position: absolute;
	right: 5px;
	top: 5px;
}
.disabled_note, .hoverable_note{
	color: rgba(255, 255, 255, 0.58);
}




@media screen and (max-width: 500px) {
	.time_schedule_slot_cell {
		width: 100px;
	}
}

