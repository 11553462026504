.time_container {
	position: absolute;
	top: calc(100% - 6px);
	left: 0;
	z-index: 3;
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-sizing: border-box;
	width: 100%;
	padding: 25px;
	padding-left: 50px;
	font-size: 14px;
	color: var(--color-field);
	background-color: var(--white-color);
	border: 1.2px solid var(--border-input);
	border-top: none;
	border-bottom: 1.2px solid var(--border-input);
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	gap: 16px;



	.grey_color{
		color:var(--color-field);
	}

	.flex_column_container{
		flex-direction: column;
		align-items: stretch;
		gap: 16px;
	}
}

.footer {
	display: flex;
	align-items: center;

	gap: 20px;
}

.submit_button {
	padding: 5px 15px;
	font-size: 14px;
}


