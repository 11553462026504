.options_container {
	position: absolute;
	top: calc(100% - 6px);
	left: 0;
	z-index: 5;
	display: flex;
	flex-direction: column;
	width: 100%;
	min-width: 100%;
	overflow: auto;
	background: var(--white-color);
	border: 1px solid var(--border-input);
	border-top: none;
	border-radius: 4px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	gap: 2px;
}
.opt{
	width: 97%;
	max-height: 180px;
	overflow-x: hidden;
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-track {
		background-color: #f1f1f1;
		border-radius: 6px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #888;
		border-radius: 8px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: #9F9F9F;
	}
}
.options_container_with_indent {
	padding: 7px 0 15px 57px;
}

.options_container::-webkit-scrollbar {
	width: 2px;
}
