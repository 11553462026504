.burger_menu_overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	display: none;
}

.burger_menu_overlay.burger_menu_opened {
	display: block;
}

.burger_menu {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	width: 393px;
	height: 100vh;
	padding-top: 57px;
	padding-left: 52px;
	background-color: rgba(255, 255, 255, 0.8);

	backdrop-filter: blur(10px);
	gap: 24px;
}

.burger_menu_close_button {
	position: absolute;
	top: 29px;
	right: 29px;
}

.burger_menu_close_button:hover {
	cursor: pointer;
}

.burger_menu_block {
	display: flex;
	flex-direction: column;
	color: var(--color-option);

	gap: 3px;
}

.burger_menu_block_title {
	font-weight: 700;
	font-size: 18px;
}

.burger_menu_block_list {
	display: flex;
	flex-direction: column;
	padding-left: 12px;
}

.burger_menu_block_list_item {
	font-size: 16px;
	font-weight: 500;
}
