.input {
	flex-grow: 1;
	border: none;
	outline: 0;
}

.label {
	font-size: 16px;
	line-height: 23px;
	color: var(--white-color);
}

.input_wrapper {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.appearance_error {
	border-color: var(--input-error-border-color);
}
