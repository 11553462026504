.section_for_org {
	display: flex;
	justify-content: center;
	max-width: unset;
	padding: 73px 15px;
	background-image: url('./../../../assets/img/start_section6.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.content {
	width: 100%;
	max-width: 1410px;
}

.section_title {
	margin-bottom: 30px;
	font-weight: 900;
	font-size: 4rem;
	line-height: 65px;
	text-align: left;
	color: var(--white-color);
}

.container_title {
	max-width: 575px;
}

.container_card {
	display: grid;
	flex-grow: 1;
	max-width: 694px;
	margin-top: 80px;

	grid-gap: 15px;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
}

.card {
	max-width: 335px;
	max-height: 312px;
	padding: 35px;
	border-radius: 8px;
}

.card:nth-child(4) {
	background-color: var(--white-color);
}

.card:nth-child(3) {
	background-color: var(--purple-light);
}

.card:nth-child(2) {
	background-color: var(--yellow-light);
}

.card:nth-child(1) {
	background-color: var(--green-light);
}

.card_title {
	font-weight: 600;
	font-size: 40px;
	line-height: 130%;
}

.black_text {
	color: var(--text-Strong);
}

.white_text {
	color: var(--white-color);
}

.card_text {
	margin-top: 20px;
	font-weight: 400;
	font-size: 2.6rem;
	line-height: 3.3rem;
}

@media screen and (max-width: 1400px) {
	.section_title {
		font-size: 4.4rem;
	}

	.card_title {
		font-size: 2.8rem;
		line-height: 3.5rem;
	}

	.card_text {
		margin-top: 40px;
		font-size: 2rem;
		line-height: 2.8rem;
	}
}

@media screen and (max-width: 1200px) {
	.section_title {
		font-size: 4rem;
	}

	.card {
		padding: 30px 25px 20px;
	}

	.card_title {
		font-size: 2.5rem;
		line-height: 3.2rem;
	}

	.card_text {
		margin-top: 30px;
		font-size: 1.8rem;
		line-height: 2.1rem;
	}
}

@media screen and (max-width: 990px) {
	.section_for_org {
		padding: 47px 15px;
	}

	.container_section {
		padding: 70px 0;
	}

	.section_title {
		font-size: 3.4rem;
		line-height: 39px;
	}

	.card {
		padding: 30px 25px 20px;
	}

	.card_title {
		font-size: 2.2rem;
		line-height: 2.5rem;
	}

	.card_text {
		margin-top: 30px;
		font-size: 1.6rem;
		line-height: 2rem;
	}
}

@media screen and (max-width: 768px) {
	.content {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.container_title {
		max-width: 335px;
	}

	.section_title {
		font-size: 3rem;
		max-width: 335px;
	}

	.container_card {
		margin-top: 30px;
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.card_text {
		margin-top: 5px;
		font-size: 1.4rem;
		line-height: 1.8rem;
	}
}


@media screen and (max-width: 575px) {
	.section_title {
		font-size: 3rem;
	}
}

@media screen and (max-width: 450px) {
	.section_title {
		font-size: 2.8rem;
	}

	.title_section {
		line-height: 31px;
	}

	.container_section {
		padding: 50px 0;
	}

	.card {
		padding: 20px;
	}

	.card_title {
		font-size: 1.6rem;
		line-height: 2rem;
	}
}
