.service_card_meta {
	display: flex;
	justify-content: space-between;
	gap: 5px;
}

.list_item_content {
	display: flex;
	flex: 1;
	justify-content: space-between;
	flex-wrap: nowrap;
	width: 100%;
}

.address_label{
	display: flex;
	align-items: center;
	width: 70%;
	white-space: pre-wrap;
	text-wrap: wrap;
	text-overflow: ellipsis;
	min-height: 42px;
}
.label {
	width: 70%;
	white-space: pre-wrap;
	text-wrap: wrap;
	text-overflow: ellipsis;
}
.label_with_tooltip {
	width: 49%;
	text-wrap: nowrap;
}
.value {
	display: flex;
	justify-content: flex-end;
	flex: 1;
	text-align: right;
	text-wrap: nowrap;
}
.value_tooltip{
	width: 94%;
}
.green {
	color: var(--green);
}

.link {
	text-decoration: underline;
	position: absolute;
	right: 12px;
}

.white {
	color: var(--white-color);
}

.link:hover {
	cursor: pointer;
}

.orange_tag {
	background-color: var(--yellow);
	text-wrap: nowrap;
}

.green_tag {
	background-color: var(--green);
}
.checked_green_tag{
	background-color: var(--white-color);
	color: var(--green);
}
.green_tag_workout{
	background-color: var(--green);
}
.tads_wrapper{
	display: flex;
	flex-wrap: wrap;
	gap: 6px;
}
.tags_slider {
	width: 100%;
	/*min-height: 105px;*/
	position: relative;
	padding: 0 12px;
}

.sport_tag {
	display: flex;
	text-wrap: nowrap;
}

.arrow_left, .arrow_right {
	position: absolute;
	top: 50%;
	margin-top: -10px;
}

.arrow_left {
	left: -5px;
}

.arrow_right {
	right: -5px;
}

.service_card_checked {
	background-color: var(--green);
	color: var(--white-color);

	.green_tag {
		background-color: var(--white-color);
		color: var(--green);
	}
}

.tooltip {
	display: inline-flex;
	padding: 9px 15px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 5px;
	background: #FFF;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
	color: var(--black-brown);
	font-family: Jost, sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 130%;
}

.title {
	font-weight: 500;
	font-size: 18px;
	line-height: 1.3em;
	text-wrap: nowrap;
	min-height: 24px;
}
