.input {
	-moz-appearance: textfield;
	flex-grow: 1;
	border: none;
	outline: 0;
	-webkit-appearance: none;
}

.label {
	font-size: 16px;
	line-height: 23px;
	color: var(--white-color);
	span {
		color: #e30000;
	}
}

.input_wrapper {
	display: flex;
	flex-direction: column;
	gap: 4px;
	position: relative;
}

.input_base {
	position: relative;
}

.appearance_error {
	border-color: var(--input-error-border-color);
}

.error {
	position: absolute;
	top: -30%;
	right: 0;
	width: fit-content;
	padding: 12px 14px;
	border-radius: 5px;
	background: rgb(203, 139, 42);
	color: var(--white-color);
	font-family: Jost, serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 2%;
	text-align: left;
}
