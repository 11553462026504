.form_container {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-width: 275px;
	max-width: 443px;
	padding: 40px 35px;
	background: var(--white-color);
	border-radius: 8px;
	gap: 35px;
}

.title {
	font-weight: 600;
	font-size: 20px;
	text-align: center;
}

.form {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	background: var(--white-color);

	gap: 25px;
}

.submit_button {
	width: 300px;
}
.input_class_props{
	width: 100%;
}

@media screen and (max-width: 480px) {
	.form_container {
		max-width: 90%;
		padding: 25px 25px;
	}
}

@media screen and (max-width: 410px) {
	.form_container {
		max-width: 95%;
		padding: 20px 15px;
	}

	.title {
		margin-bottom: 15px;
		font-size: 18px;
	}
}
